module.exports = new Promise(resolve => {
  const url = window.__RUNTIME_CONFIG__.STAFF_CHAT_REMOTE_URL + '/remoteEntry.js'
  const script = document.createElement('script')
  script.src = url

  script.onload = () => {
    // the injected script has loaded and is available on window
    // we can now resolve this Promise
    const proxy = {
      get: (request) => window.staff_web_chat.get(request),
      init: (arg) => {
        try {
          return window.staff_web_chat.init(arg)
        } catch(e) {
          console.log('remote container already initialized')
        }
      }
    }
    resolve(proxy)
  }

  script.onerror = (error) => {
    console.log('error loading remote container staff_web_chat')
    const proxy = {
      get: (request) => {
        return Promise.resolve(() => () => '');
      },
      init: (arg) => {
        return;
      }
    }
    resolve(proxy)
  }
  // inject this script with the src set to the remoteEntry.js
  document.head.appendChild(script);
})
;